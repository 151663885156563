import { useLoaderData } from "@remix-run/react";
import { json } from "@remix-run/node";
import type {
  // LinksFunction,
  // MetaFunction,
  LoaderFunction,
} from "@remix-run/node";
import type { IMedia } from "~/types";
import type {
  InklusionLinkCards,
  RecommendationSectionProp,
  InkusionSectionProp,
  ITeamProps,
} from "~/api/get_inkusion_data.server";

import { fetchInclusionData } from "~/api";
import { TeamSection, RelatedMediaSection } from "~/shared";
import {
  InKlusionCardList,
  InklusionInfoSection,
  RecommendationSection,
} from "~/screens";

import mediaImage from "~/assets/images/inkusion/inkusion-media-section.svg";

type LoaderData = {
  inklusionCards: InklusionLinkCards[];
  recomendationSection: RecommendationSectionProp;
  infoSection: InkusionSectionProp;
  teamMembers: ITeamProps[];
  media: IMedia[];
  title: string;
  subtitle: string;
};

export const loader: LoaderFunction = async ({ context, request }) => {
  // const url = new URL(request.url);

  const {
    teamMembers,
    inklusionCards,
    recomendationSection,
    infoSection,
    media,
    title,
    subtitle,
  } = await fetchInclusionData();

  return json(
    {
      teamMembers,
      inklusionCards,
      recomendationSection,
      infoSection,
      media,
      title,
      subtitle,
    },
    {
      headers: {
        // max-age controls the browser cache
        // s-maxage controls a CDN cache
        "Cache-Control": "public, max-age=30, s-maxage=86400",
      },
    }
  );
};

export default function Inklusion() {
  // const location = useLocation();
  //@ts-ignore

  const {
    teamMembers,
    inklusionCards,
    infoSection,
    recomendationSection,
    media,
  } = useLoaderData<LoaderData>();
  // console.log("teamMembers", teamMembers);
  return (
    <div className="flex flex-col bg-slate-50 px-3 md:px-0">
      <InKlusionCardList cards={inklusionCards} />
      <div className="bg-white">
        <InklusionInfoSection {...infoSection} />
      </div>
      <div className="relative">
        <div className="-z-10 hidden h-full w-full flex-col pt-0 md:flex">
          <div className="absolute  top-0 w-full">
            <img alt="" className="w-full object-bottom" src={mediaImage} />
          </div>
        </div>
        <div className="md:pt-28">
          <RelatedMediaSection
            masonry
            to="/search/main?inclusionIds=1&inclusionIds=2&inclusionIds=3"
            mediaCards={media}
            title={
              <h2 className="text-2xl leading-tight text-primary lg:text-6xl">
                Medien
              </h2>
            }
          />
        </div>
      </div>
      <RecommendationSection {...recomendationSection} />
      <TeamSection team={teamMembers} />
    </div>
  );
}
